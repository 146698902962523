// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Playwrite+CU:wght@100..400&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: #889CB6;
  color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.App {
  text-align: center;
  width: 100%;
}

.logo {
  width: calc(30px + 7.5vmin);
  transform: rotate(30deg);
  margin-bottom: 10px;
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
}

h1 {
  font-size: calc(10px + 10vmin);
  margin: 0;
  font-weight: 300;
  font-optical-sizing: auto;
  font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAIA;EACE,SAAA;EACA,UAAA;EACA,sBAAA;AAAF;;AAGA;EACE,YAAA;AAAF;;AAGA;EAEE,iCAAA;EAEA,yBAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AAFF;;AAKA;EACE,kBAAA;EACA,WAAA;AAFF;;AAKA;EACE,2BAAA;EACA,wBAAA;EACA,mBAAA;EACA,oDAAA;AAFF;;AAKA;EACE,8BAAA;EACA,SAAA;EAEA,gBAAA;EAEA,yBAAA;EACA,kBAAA;AAJF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Playwrite+CU:wght@100..400&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\nhtml, body {\n  height: 100%;\n}\n\nbody {\n  // font-family: \"Playwrite CU\", cursive;\n  font-family: \"Roboto\", sans-serif;\n  // font-family: \"Open Sans\", sans-serif;\n  background-color: #889CB6;\n  color: #FFF;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n}\n\n.App {\n  text-align: center;\n  width: 100%;\n}\n\n.logo {\n  width: calc(30px + 7.5vmin);\n  transform: rotate(30deg);\n  margin-bottom: 10px;\n  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));\n}\n\nh1 {\n  font-size: calc(10px + 10vmin);\n  margin: 0;\n  // font-weight: bold;\n  font-weight: 300;\n  // font-style: Thin;\n  font-optical-sizing: auto;\n  font-style: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
