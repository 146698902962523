import React from 'react';
import './App.scss';

function App() {
  return (
    <main className="App">
      <img src="/images/logo.svg" alt="Kinary Logo" style={{ width: '100px', height: 'auto' }} />
      <h1>Kinary</h1>
    </main>
  );
}

export default App;